import { Modal } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react'
import FileInput from '../core/file-input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../core/button';
import Anchor from '../core/anchor';
import TextField from '../core/text-field';
import { MdAddPhotoAlternate, MdGroups } from 'react-icons/md'
import { useMutation, useQuery } from 'react-query';
import agent from '../../api/agent';
import { date } from 'yup/lib/locale';
import { toast } from 'react-toastify';
import { FiEdit, FiEdit2 } from 'react-icons/fi'
import { AiOutlineCheck } from 'react-icons/ai';

const UserProfileModal = ({
    modalShow,
    setModalShow,
    userData,
    currentUser,
    recentRefetch,
}) => {
    const [groupMembers, setGroupMembers] = useState([])
    const [picturePreview, setPicturePreview] = useState(null);
    const [fileInputKey, setFileInputKey] = useState(0);
    const [groupName, setGroupName] = useState(userData && (userData.ContactType === 'Group' && userData.GroupInfo[0].GroupName));
    const [nameEdit, setNameEdit] = useState(false);
    const [file, setFile] = useState(null);
    const { data, refetch } = useQuery(["group-member-list", userData], () => agent.User.loadAllGroupMembers({ GroupId: userData && userData.FriendID }),
        {
            refetchOnWindowFocus: false,//turned off on window focus refetch option
            enabled: false, // turned off by default, manual refetch is needed
            onSuccess: (d) => {
                setGroupMembers(d[0].MemberInfo);
            }
        }
    );
    const { isLoading, mutate } = useMutation((values) =>
        agent.User.removeGroupMember(values), { retry: 2 }
    );
    const { isLoading: picLoading, mutate: picMutate } = useMutation((values) =>
        agent.User.editGroupImage(values), { retry: 2 }
    );
    const { isLoading: nameLoading, mutate: nameMutate } = useMutation((values) =>
        agent.User.editGroupName(values), { retry: 2 }
    );
    useEffect(() => {
        if (userData !== null) {
            refetch();
        }
        setNameEdit(!nameEdit)
    }, [modalShow])

    const modalClick = (e) => {
        if (e.target.className === "fade modal show") {
            setModalShow(!modalShow);
        }
    };
    const handleRemoveUser = (e, item) => {
        e.preventDefault();
        const values = {
            GroupId: data && data[0]._id,
            MemberId: item._id
        }
        mutate(values, {
            onSuccess: (data) => {
                if (data === 'success') {
                    refetch();
                    toast.success('Removed Successfully');
                } else {
                    toast.warning('Failed to remove');
                }
            },
            onError: (error) => {
                toast.warning(error);
            }
        })
    }
    function handleChooseImage(e) {
        e.preventDefault();
        var input = document.createElement("input");
        input.type = "file";
        input.multiple = false;
        input.accept = '.png,.jpg,.jpeg';
        input.click();
        input.onchange = (e) => {
            var file = e.currentTarget.files[0]
            if (file.type === "" || file.type.includes('application')) {
                toast.error('Only image accepted')
                return false;
            }
            if (file.type !== "" || file.type.includes('png') || file.type.includes('jpg') || file.type.includes('jpeg')) {
                setFile(file);
                setPicturePreview(URL.createObjectURL(e.currentTarget.files[0]))
                let formData = new FormData();
                formData.append('GroupId', userData && userData.GroupInfo[0]._id)
                formData.append('file', file)
                picMutate(formData, {
                    onSuccess: (data) => {
                        if (data.Status === 'success') {
                            recentRefetch();
                            userData.GroupInfo[0].GroupPic = data.Image;
                            toast.success('Group image changed successfully');
                        } else {
                            toast.warning('Failed to remove');
                        }
                    },
                    onError: (error) => {
                        toast.warning(error);
                    }
                })

            } else {
                toast.error('Invalid Image Format')
                return false;
            }

        }
    }
    function handleChangeName(e) {
        e.preventDefault();
        if (groupName === '') {
            toast.warning('Group name is required')
        } else {
            const values = {
                GroupId: userData && userData.GroupInfo[0]._id,
                GroupName: groupName
            }
            nameMutate(values, {
                onSuccess: (data) => {
                    if (data === 'success') {
                        recentRefetch();
                        userData.GroupInfo[0].GroupName = groupName;
                        setNameEdit(!nameEdit);
                        toast.success('Group name changed Successfully');
                    } else {
                        toast.warning('Failed to change');
                    }
                },
                onError: (error) => {
                    toast.warning(error);
                }
            })
        }
    }
    const imgCss = {
        width: '125px',
        height: '125px',
        borderRadius: '50%',
        position: 'relative',
        left: '20px'
    }
    return (
        <Fragment>
            <div onClick={(e) => modalClick(e)}>
                <Modal show={modalShow} size="md" style={{ background: ' rgba(0, 0, 0, 0.7)' }} centered>
                    <Modal.Header className="p-0 border-0">
                        <div className="row w-100">
                            <div className="col-6 pt-2 ps-4">
                                <h5 className='wrap-text'>
                                    {
                                        userData && userData.ContactType === 'Group' ? 'Group' : 'Profile'
                                    }
                                </h5>
                            </div>
                            <div className="col-6 p-2 pe-3 text-right">
                                <span className="cursor-pointer" onClick={(e) => { setModalShow(!modalShow); }}><i className="fas fa-times"></i></span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        {
                            userData && userData.ContactType === 'Person' &&
                            <div className="row">
                                <div className="col-12 text-center mt-4">

                                    <img src={userData && userData.UserInfo[0] && userData.UserInfo[0].ProfilePic ? process.env.REACT_APP_API_URL + userData.UserInfo[0].ProfilePic : "/dist/img/user.png"} style={{ width: '125px', height: '125px', borderRadius: '50%' }} alt="own_img" onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/dist/img/user.png";
                                    }} />
                                </div>
                                <div className="col-12 mt-2 mb-4 text-center">
                                    <h3 className='mb-0'>{userData && userData.UserInfo && userData.UserInfo[0].firstname + ' ' + userData.UserInfo[0].name}</h3>
                                    <h5 className="text-muted mt-0">{userData && userData.EmailAddress}</h5>
                                </div>

                            </div>
                        }
                        {
                            userData && userData.ContactType === 'User' &&
                            <div className="row">
                                <div className="col-12 text-center mt-4">

                                    <img src={userData && userData.ProfilePic ? process.env.REACT_APP_API_URL + userData.ProfilePic : "/dist/img/user.png"} style={{ width: '125px', height: '125px', borderRadius: '50%' }} alt="own_img" onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/dist/img/user.png";
                                    }} />
                                </div>
                                <div className="col-12 mt-2 mb-4 text-center">
                                    <h3 className='mb-0'>{userData && userData.firstname + ' ' + userData.name}</h3>
                                    <h5 className="text-muted mt-0">{userData && userData.EmailAddress}</h5>
                                </div>

                            </div>
                        }
                        {
                            userData && userData.ContactType === 'Group' &&
                            <div className="row">
                                <div className="col-12 text-center mt-4">

                                    <img src={userData &&
                                        userData.GroupInfo &&
                                        userData.GroupInfo[0].GroupPic ?
                                        process.env.REACT_APP_API_URL + userData.GroupInfo[0].GroupPic : "/dist/img/group.png"}
                                        style={imgCss} alt="own_img" onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "/dist/img/group.png";
                                        }} />
                                    <a className="btn btn-primary mt-2" onClick={(e) => handleChooseImage(e)}
                                        style={{ borderRadius: '50%', padding: '5px', position: 'relative', top: '20px', left: '-5px' }}>
                                        <MdAddPhotoAlternate size={24} className={'ico-postion'} />
                                    </a>
                                </div>
                                <div className="col-12 mt-2 mb-4 text-center">
                                    <h3 className='mb-0'>
                                        <MdGroups size={20} style={{ position: 'relative', top: '-2px' }} />&nbsp;
                                        {!nameEdit ?
                                            (userData && userData.GroupInfo && userData.GroupInfo[0].GroupName)
                                            :
                                            <input type="text" placeholder='Group name' className='search-user w-50' value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                                        }
                                        {nameEdit ?
                                            <AiOutlineCheck size={18} className={'ms-2'} onClick={(e) => handleChangeName(e)} />
                                            : <FiEdit2 size={18} className={'ms-2'} onClick={() => { setNameEdit(!nameEdit); setGroupName(userData.GroupInfo[0].GroupName) }} />}
                                    </h3>
                                    <h5 className="text-muted mt-0">{userData && userData.EmailAddress}</h5>
                                </div>
                            </div>
                        }

                        {
                            userData && userData.ContactType === 'Group' &&
                            <Fragment>
                                <div className="col-12">
                                    <h6>Member Info:</h6>
                                </div>
                                <div className="col-12 user-list-div">
                                    <table className="table table-striped table-borderless">
                                        <tbody>
                                            {
                                                groupMembers.length > 0 &&
                                                groupMembers.map((item, key) => (
                                                    <tr key={key}>
                                                        <td style={{ paddingLeft: '10px' }}>
                                                            <div className="d-flex">
                                                                <label style={{
                                                                    width: '100%',
                                                                    margin: '0px'
                                                                }}>
                                                                    <img src={item && item.ProfilePic ? process.env.REACT_APP_API_URL + item.ProfilePic : "/dist/img/user.png"} alt="user-pic" className='list-user-pic' onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src = "/dist/img/user.png";
                                                                    }} /><span className="list-user-name wrap-text">{item.firstname + ' ' + item.name}</span>
                                                                    {data &&
                                                                        data[0].Admin === currentUser._id &&
                                                                        item._id !== currentUser._id &&
                                                                        <button className="btn btn-danger float-right" onClick={(e) => handleRemoveUser(e, item)}>
                                                                            {
                                                                                isLoading ?
                                                                                    <span
                                                                                        className="spinner-border spinner-border-sm me-3"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    ></span>
                                                                                    :
                                                                                    'Remove'
                                                                            }
                                                                        </button>}
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </Fragment>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </Fragment>
    )
}

export default UserProfileModal