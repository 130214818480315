import React, { Fragment, useState } from 'react'
import { BsCheck2All } from 'react-icons/bs';
import ImgPrevModal from './img-prev-moda';

const ChatLeftImage = ({ message }) => {
    const [modalShow, setModalShow] = useState(false)
    async function toDataURL(url) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }
    async function downloadImg(message) {
        var el = document.createElement("a");
        el.setAttribute("href", await toDataURL(process.env.REACT_APP_API_URL + message.Message.slice(3)));
        el.setAttribute("download", message.FileName);
        document.body.appendChild(el);
        el.click();
        el.remove();
    }
    const handleCheckDate = (dateTime) => {
        var d = new Date();
        var today = ("0" + d.getDate()).slice(-2) +
            "/" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "/" +
            d.getFullYear()

        var getDate = dateTime.slice(0, 10);
        if (today === getDate) {
            return dateTime.slice(11);
        } else {
            if (d.getFullYear().toString() === dateTime.slice(6, 10)) {
                return dateTime.slice(0, 5) + dateTime.slice(10);
            } else {
                return dateTime;
            }
        }
    }

    return (
        <Fragment>
            <div className="left-chat">
                {
                    message.ReceiverStatus === 'Active'
                    &&
                    <>
                        <div className="chat-img">
                            <img src={message.SenderInfo && message.SenderInfo.length > 0 ? message.SenderInfo[0].ProfileIcon ? message.SenderInfo[0].ProfileIcon : "/dist/img/user.png" : "/dist/img/user.png"} onError={i => i.target.style.display = 'none'} />
                        </div>
                        <div className="left-msg">
                            <div className="col-12 mb-0 text-left ps-0">
                                <span onClick={() => setModalShow(!modalShow)}>
                                    <img src={process.env.REACT_APP_API_URL + message.Message.slice(3)} alt="left-img" style={{
                                        width: '175px', height: '175px', borderRadius: '20px 20px 20px 0px', background: 'gainsboro',
                                        border: '2px solid var(--indigo)'
                                    }} />
                                </span>
                            </div>
                            <div className="col-12 p-0 text-left mt-2" style={{ position: 'relative', fontSize: '13px' }}>
                                <span>{handleCheckDate(message.DateTime)}</span>
                            </div>
                        </div>

                    </>
                }

            </div>
            <ImgPrevModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                message={message}
            />
        </Fragment>
    )
}

export default ChatLeftImage;
