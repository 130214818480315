import { Modal } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react'
import FileInput from '../core/file-input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../core/button';
import Anchor from '../core/anchor';
import TextField from '../core/text-field';
import agent from '../../api/agent';
import { useMutation } from 'react-query'
import { toast } from 'react-toastify';
import { setCurrentUser } from '../../redux/user/user.action';
import { connect } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selector';
import { createStructuredSelector } from 'reselect'
const OwnProfileModal = ({
    modalShow,
    setModalShow,
    currentUser,
    setCurrentUser
}) => {
    const [picturePreview, setPicturePreview] = useState(null);
    const [fileInputKey, setFileInputKey] = useState(0);
    const [file, setFile] = useState(null);
    const [editable, setEditable] = useState(false);
    const { isLoading, isError, mutate } = useMutation((values) =>
        agent.User.editOwnProfile(values), { retry: 2 }
    );
    useEffect(() => {
        if (modalShow === true) {
            setPicturePreview(null);
            setFileInputKey(null);
            setEditable(false);
        }
    }, [modalShow])
    const formik = useFormik({
        initialValues: {
            Name: '',
            EmailAddress: '',
            Password: '',
            file: ''
        },
        validationSchema: Yup.object({
            Name: Yup.mixed().required(),
            EmailAddress: Yup.mixed().required(),
            Password: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            // let formData = new FormData();
            // formData.append('UserId', currentUser._id);
            // formData.append('Name', values.Name);
            // formData.append('EmailAddress', values.EmailAddress);
            // formData.append('Password', values.Password);
            // formData.append('file', values.file);

            // mutate(formData, {
            //     onSuccess: (data) => {
            //         console.log(data);
            //         if (data.Status === 'success') {
            //             resetForm({});
            //             setCurrentUser(data.message[0]);
            //             toast.success('Profile updated successfully');
            //             setEditable(!editable);
            //         } else
            //             toast.error('Failed to update');
            //     },
            //     onError: (error) => {
            //         toast.error('An error has been occured');
            //     }
            // })
        }
    })
    useEffect(() => {
        formik.setFieldValue('Name', currentUser && currentUser.firstname + ' ' + currentUser.name);
        formik.setFieldValue('EmailAddress', currentUser && currentUser.idforchat);
        formik.setFieldValue('Password', currentUser.Password);
    }, [currentUser])
    const modalClick = (e) => {
        if (e.target.className === "fade modal show") {
            setModalShow(!modalShow);
        }
    };
    const onChangeImageInput = (e) => {
        setFile(e.currentTarget.files[0]);
        formik.setFieldValue('file', e.currentTarget.files[0])
    };
    return (
        <Fragment>
            <div onClick={(e) => modalClick(e)}>
                <Modal show={modalShow} size="lg" style={{ background: ' rgba(0, 0, 0, 0.7)' }} centered>
                    <Modal.Header className="p-0 border-0">
                        <div className="row w-100">
                            <div className="col-6 pt-2 ps-4">
                                <h5>Profile</h5>
                            </div>
                            <div className="col-6 p-2 pe-3 text-right">
                                <span className="cursor-pointer" onClick={(e) => { setModalShow(!modalShow); }}><i className="fas fa-times"></i></span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-12 text-center mt-4">
                                    {
                                        picturePreview ?
                                            <img src={picturePreview} style={{ width: '225px', height: '225px', borderRadius: '50%' }} alt="own_img" onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "/dist/img/user.png";
                                            }} />
                                            :
                                            <img src={currentUser && currentUser.ProfilePic ? process.env.REACT_APP_API_URL + currentUser.ProfilePic : "/dist/img/user.png"} style={{ width: '225px', height: '225px', borderRadius: '50%' }} alt="own_img" onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "/dist/img/user.png";
                                            }} />

                                    }
                                </div>
                                {/* {editable === false &&
                                    <div className="col-12 mb-2 text-center">
                                        <Anchor title={''} style={{ height: '40px' }} onClick={() => setEditable(!editable)} ButtonText={<Fragment><i className='fas fa-edit'></i>&nbsp;<span>Edit</span></Fragment>} className={'btns-primary mt-2'} />
                                    </div>
                                } */}
                                {
                                    editable === true &&
                                    <Fragment>
                                        <div className="col-4"></div>
                                        <div className="col-4 mt-2 text-center">
                                            <FileInput
                                                name={'file'}
                                                accept={'image /x-png,image/jpeg'}
                                                setPicturePreview={setPicturePreview}
                                                picturePreview={picturePreview}
                                                className={'text-white'}
                                                fileInputKey={fileInputKey}
                                                setFile={setFile}
                                                formik={formik}
                                                onChangeInput={onChangeImageInput}
                                            />
                                        </div>
                                        <div className="col-4"></div>
                                    </Fragment>
                                }
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8">
                                        <div className="col-md-12 mt-4 mb-4 p-0">
                                            <TextField
                                                label={'Name'}
                                                name={'Name'}
                                                type={'text'}
                                                readonly={!editable}
                                                {...formik.getFieldProps('Name')}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-4 p-0">
                                            <TextField
                                                label={'Chat Id'}
                                                name={'EmailAddress'}
                                                readonly={!editable}
                                                type={'text'}
                                                {...formik.getFieldProps('EmailAddress')}
                                            />
                                        </div>
                                        {/* <div className="col-md-12 mb-4 p-0">
                                            <TextField
                                                label={'Password'}
                                                name={'Password'}
                                                readonly={!editable}
                                                type={'password'}
                                                {...formik.getFieldProps('Password')}
                                            />
                                        </div> */}
                                        {
                                            editable &&
                                            <div className="col-12 mb-4">
                                                <Button type={'submit'} title={''} disabled={!editable && formik && !(formik.isValid && formik.dirty)} ButtonText={'Update'} className={'btns-primary w-100'} style={{ height: '50px' }} />
                                            </div>
                                        }

                                    </div>
                                    <div className="col-md-2"></div>
                                </div>

                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </Fragment>
    )
}
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});
const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user))
})
export default connect(mapStateToProps, mapDispatchToProps)(OwnProfileModal)