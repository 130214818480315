import React, { Fragment } from 'react'
import { Tooltip } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

const Button = ({ className, ButtonText, title, disabled, isLoading, placement, style, props, type, onClick }) => {

    return (
        <Fragment>
            {
                title !== '' ?
                    <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                            <Tooltip className='custom-tooltip' {...props}>
                                {title}
                            </Tooltip>
                        )}
                        placement={placement}
                    >
                        <button
                            disabled={disabled}
                            className={`btn-root ${className}`} type={type} onClick={onClick} style={style} {...props}>
                            {
                                isLoading
                            }
                            <span style={{ position: 'relative', top: '-3px' }}> {ButtonText}</span> </button>
                    </OverlayTrigger>
                    :
                    <button
                        disabled={disabled}
                        className={`btn-root ${className}`} type={type} onClick={onClick} style={style} {...props}>
                        {
                            isLoading
                        }
                        <span style={{ position: 'relative', top: '-3px' }}>{ButtonText}</span></button>
            }
        </Fragment>
    )
}

export default Button