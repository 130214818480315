import React, { Fragment, useEffect } from 'react'
import { BsCheck2All } from 'react-icons/bs';
import { MdGroups } from 'react-icons/md'

const ChatRow = ({ currentUser, changeDiv, userData, userDetails }) => {
    const handleCheckDate = (dateTime) => {
        var d = new Date();
        var today = ("0" + d.getDate()).slice(-2) +
            "/" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "/" +
            d.getFullYear()

        var getDate = dateTime.slice(0, 10);
        if (today === getDate) {
            return dateTime.slice(11);
        } else {
            if (d.getFullYear().toString() === dateTime.slice(6, 10)) {
                return dateTime.slice(0, 5) + dateTime.slice(10);
            } else {
                return dateTime;
            }
        }
    }
    // const formatTime = (date) => {
    //     var hours = date.getHours();
    //     var minutes = date.getMinutes();
    //     var am = hours >= 12 ? 'pm' : 'am';
    //     hours = hours % 12;
    //     hours = hours ? hours : 12; // the hour '0' should be '12'
    //     minutes = minutes < 10 ? '0' + minutes : minutes;
    //     var strTime = hours + ':' + minutes + ' ' + am;
    //     return strTime;
    // }
    // const milisecondToTime = (ms) => {
    //     var seconds = (ms / 1000).toFixed(0);
    //     var minutes = (ms / (1000 * 60)).toFixed(0);
    //     var hours = (ms / (1000 * 60 * 60)).toFixed(0);
    //     var days = (ms / (1000 * 60 * 60 * 24)).toFixed(0);
    //     if (seconds < 60) return "Now";
    //     else if (minutes < 60) return minutes + " minute";
    //     else if (hours < 24) return hours + " hr";
    //     else return days + " Days";
    // }

    function handleMessageText(message) {

        if (userData && message) {
            if (message.SenderID === currentUser._id) {
                if (message.Type === 'text') {
                    return message.Message;
                } else if (message.Type === 'image') {
                    return 'Vous avez envoyé une image'
                } else if (message.Type === 'file') {
                    return 'Vous avez envoyé un fichier'
                }
            } else {
                if (message.Type === 'text') {
                    return message.Message;
                } else if (message.Type === 'image') {
                    return userData && userData.UserInfo[0].firstname + ' a envoyé une image'
                } else if (message.Type === 'file') {
                    return userData && userData.UserInfo[0].firstname + ' a envoyé un fichier'
                }
            }
        }
    }

    return (
        <Fragment>
            {
                userData &&
                (<Fragment>
                    {
                        userData.ContactType === 'Person' &&
                        <Fragment>
                            <div className={`row chat-row ${userData.NotificationStatus === 'Yes' && 'fw-bold'} ${userDetails && userDetails._id === userData.UserInfo[0]._id && 'selected-chat'}`} onClick={() => (!userDetails && changeDiv(userData), userDetails && userDetails._id !== userData.UserInfo[0]._id && changeDiv(userData))}>
                                <div className="chat-left">
                                    {/* process.env.REACT_APP_API_URL + */}
                                    <img src={userData && userData.UserInfo[0].ProfileIcon ? userData.UserInfo[0].ProfileIcon : "/dist/img/user.png"} onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/dist/img/user.png";
                                    }} />
                                </div>
                                <div className="chat-middle">
                                    <h6 className={`chat-name  ${userData.NotificationStatus === 'Yes' && 'fw-bolder'}`}>{userData ? userData.UserInfo[0].UserName : ''}</h6>
                                    <p className='last-chat'>
                                        {
                                            userData &&
                                            userData.LastMessageData &&
                                            ((userData.LastMessageData[0] && userData.LastMessageData[0].SenderID === currentUser._id &&
                                                userData.LastMessageData[0] && userData.LastMessageData[0].SeenStatus === "Unseen") ?
                                                <BsCheck2All className='me-2' /> :
                                                (userData.LastMessageData[0] && userData.LastMessageData[0].SenderID === currentUser._id &&
                                                    userData.LastMessageData[0] && userData.LastMessageData[0].SeenStatus !== "Unseen") &&
                                                <BsCheck2All className='text-primary me-2' />)
                                        }
                                        {
                                            userData &&
                                                userData.LastMessageData.length > 0 ?
                                                handleMessageText(userData.LastMessageData[0])
                                                : 'Aucun message trouvé'
                                        }</p>
                                </div>
                                <div className="chat-right">
                                    <p className="chat-time">{
                                        userData &&
                                        userData.LastMessageData &&
                                        userData.LastMessageData[0] &&
                                        (handleCheckDate(userData.LastMessageData[0].DateTime))
                                    }</p>
                                    {userData.NotificationStatus === 'Yes' && <span className="badge bg-primary border-radius-50">{userData.NotificationCount}</span>}
                                </div>
                            </div>
                        </Fragment>
                    }

                </Fragment>)
            }
        </Fragment >
    )
}

export default ChatRow
