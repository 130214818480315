import React, { Fragment, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { BiCheck } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { CgUnblock } from 'react-icons/cg';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import agent from '../../../api/agent';

const UserRow = ({ changeDiv, userData, userDetails, currentUser, refetch, blockRefetch }) => {

    const { isLoading: acceptLoading, mutate: acceptMutate } = useMutation((values) =>
        agent.User.acceptFriendRequest(values), { retry: 2 }
    );
    const { isLoading: rejectLoading, mutate: rejectMutate } = useMutation((values) =>
        agent.User.rejectFriendRequest(values), { retry: 2 }
    );
    const { isLoading, mutate } = useMutation((values) =>
        agent.User.blockAnyUser(values), { retry: 2 }
    );
    const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation((values) =>
        agent.User.deleteContactRow(values), { retry: 2 }
    );
    const { isLoading: blockLoading, mutate: blockMutate } = useMutation((values) =>
        agent.User.unblockUser(values), { retry: 2 }
    );
    const handleAccept = (e, item) => {
        e.preventDefault();
        const values = {
            UserId: currentUser._id,
            SelectId: item.FriendID
        }
        acceptMutate(values, {
            onSuccess: (data) => {
                if (data === 'success') {
                    refetch();
                    blockRefetch();
                    toast.success('Accepté avec succès');
                } else {
                    toast.warning('Échec de l\'acceptation');
                }
            },
            onError: (error) => {
                toast.warning(error);
            }
        })
    }
    const handleReject = (e, item) => {
        e.preventDefault();
        const values = {
            UserId: currentUser._id,
            SelectId: item.FriendID
        }

        rejectMutate(values, {
            onSuccess: (data) => {
                if (data === 'success') {
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className="card">
                                    <div className="card-body p-5" style={{ minWidth: '300px' }}>

                                        <h3 className='mb-4 fw-500'>Voulez-vous bloquer cet utilisateur ?</h3>
                                        <div className="row">
                                            <div className="col-6">
                                                <button className="btn btn-danger w-100" onClick={() => {
                                                    onClose();
                                                    refetch();
                                                    blockRefetch();
                                                    toast.success('Rejeté avec succès');
                                                }} >Non</button>
                                            </div>
                                            <div className="col-6">
                                                <button className="btn btn-success w-100" onClick={() => {
                                                    mutate(values, {
                                                        onSuccess: (data) => {
                                                            if (data === 'success') {
                                                                refetch();
                                                                blockRefetch();
                                                                onClose();
                                                                toast.success('Bloqué avec succès');
                                                            } else {
                                                                toast.warning('Impossible de bloquer');
                                                            }
                                                        },
                                                        onError: (error) => {
                                                            toast.warning(error);
                                                        }
                                                    })
                                                }}>Oui</button>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            );
                        }
                    });
                } else {
                    toast.warning('Impossible de rejeter');
                }
            },
            onError: (error) => {
                toast.warning(error);
            }
        })
    }
    const handleUnblock = (e, item) => {
        e.preventDefault();
        const values = {
            UserId: currentUser._id,
            SelectId: item.FriendID
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="card">
                        <div className="card-body p-5" style={{ minWidth: '300px' }}>

                            <h3 className='mb-4 fw-500'>Êtes-vous sûr de débloquer cet utilisateur ?</h3>
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn btn-danger w-100" onClick={() => {
                                        onClose();
                                        refetch();
                                        blockRefetch();
                                    }} >Non</button>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-success w-100" onClick={() => {
                                        blockMutate(values, {
                                            onSuccess: (data) => {
                                                if (data === 'success') {
                                                    refetch();
                                                    blockRefetch();
                                                    onClose();
                                                    toast.success('Débloqué avec succès');
                                                } else {
                                                    toast.warning('Échec du déblocage');
                                                }
                                            },
                                            onError: (error) => {
                                                toast.warning(error);
                                            }
                                        })
                                    }}>Oui</button>
                                </div>
                            </div>
                        </div>
                    </div >
                );
            }
        });
    }
    const handleDelete = (e, item) => {
        e.preventDefault();
        const values = {
            UserId: currentUser._id,
            SelectId: item.FriendID
        }

        deleteMutate(values, {
            onSuccess: (data) => {
                if (data === 'success') {
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className="card">
                                    <div className="card-body p-5" style={{ minWidth: '300px' }}>

                                        <h3 className='mb-4 fw-500'>Voulez-vous bloquer cet utilisateur ?</h3>
                                        <div className="row">
                                            <div className="col-6">
                                                <button className="btn btn-danger w-100" onClick={() => {
                                                    onClose();
                                                    refetch();
                                                    blockRefetch();
                                                    toast.success('Supprimé avec succès');
                                                }} >Non</button>
                                            </div>
                                            <div className="col-6">
                                                <button className="btn btn-success w-100" onClick={() => {
                                                    mutate(values, {
                                                        onSuccess: (data) => {
                                                            if (data === 'success') {
                                                                refetch();
                                                                blockRefetch();
                                                                onClose();
                                                                toast.success('Bloqué avec succès');
                                                            } else {
                                                                toast.warning('Impossible de bloquer');
                                                            }
                                                        },
                                                        onError: (error) => {
                                                            toast.warning(error);
                                                        }
                                                    })
                                                }}>Oui</button>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            );
                        }
                    });
                } else {
                    toast.warning('Échec de la suppression');
                }
            },
            onError: (error) => {
                toast.warning(error);
            }
        })
    }

    return (
        <Fragment>
            {
                userData &&
                (<Fragment>
                    <div className={`row chat-row ${userDetails && userDetails._id === userData._id && 'selected-chat'}`} onClick={() => userData.State === '1' && (changeDiv(userData))}>
                        <div className="chat-left">
                            {/* process.env.REACT_APP_API_URL + */}
                            <img src={userData && userData.UserInfo && userData.UserInfo[0].ProfileIcon ? userData.UserInfo[0].ProfileIcon : "/dist/img/user.png"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/dist/img/user.png";
                            }} />
                        </div>
                        <div className="chat-middle">
                            <h6 className='chat-name' style={{ position: 'relative', top: '5px' }}>{userData && userData.UserInfo ? userData.UserInfo[0].UserName : ''}</h6>
                        </div>
                        <div className="chat-right">
                            {userData.State === "2" &&
                                <div className="h6 text-warning mt-3">En attendant</div>
                            }
                            {userData.State === "5" &&
                                <a className="btn btn-primary p-1 mt-2" title='Débloquer' onClick={(e) => { handleUnblock(e, userData); }}><CgUnblock size={20} /></a>

                            }
                            {userData.State === "4" &&
                                <div className="d-block">
                                    <div className="h6 text-danger mt-1">Rejeté</div>
                                    <a className="btn btn-danger p-1" title='Supprimer' onClick={(e) => { handleDelete(e, userData); }}><BsTrash size={20} /></a>

                                </div>
                            }
                            {userData.State === "3" &&
                                <div className="d-block">
                                    <a className="btn btn-success p-1" title='Accept' onClick={(e) => { handleAccept(e, userData); }}><BiCheck size={20} /></a>
                                    <a className="btn btn-danger p-1 ms-1" title='Reject' onClick={(e) => { handleReject(e, userData); }}><BsTrash size={20} /></a>
                                </div>
                            }
                        </div>
                    </div>
                </Fragment>)
            }
        </Fragment >
    )
}

export default UserRow
