import React, { Fragment, useEffect } from 'react'
import { BsCheck2All } from 'react-icons/bs';
import Linkify from 'react-linkify';
import LinkPreview from '../../core/link-preview';


const ChatRight = ({ message, IsWhatsapp, currentUser }) => {
    const handleCheckDate = (dateTime) => {
        var d = new Date();
        var today = ("0" + d.getDate()).slice(-2) +
            "/" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "/" +
            d.getFullYear()

        var getDate = dateTime.slice(0, 10);
        if (today === getDate) {
            return dateTime.slice(11);
        } else {
            if (d.getFullYear().toString() === dateTime.slice(6, 10)) {
                return dateTime.slice(0, 5) + dateTime.slice(10);
            } else {
                return dateTime;
            }
        }
    }
    const getUrl = (url) => {
        var urlRE = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
        return url.match(urlRE);
    }

    return (
        <Fragment>
            <div className="right-chat">
                {
                    message.SenderStatus === 'Active'
                    &&
                    <>
                        <div className="right-msg">
                            <div className="col-12 text-right pe-0">
                                {
                                    message.UserName
                                }
                            </div>
                            <div className={`right-chat-pointer`}></div>
                            <div className={`chat shadow ${IsWhatsapp ? 'bg-whatsapp' : 'bg-messeger'}`}>

                                <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <Fragment>
                                            {
                                                getUrl(message.Message) !== null &&
                                                <LinkPreview
                                                    className='link-preview'
                                                    url={getUrl(message.Message)[0]}
                                                />
                                            }
                                            <a href={decoratedHref} className={'text-white'} key={key} target="_blank">
                                                {decoratedText}
                                            </a>
                                        </Fragment>
                                    )}> {message.Message}</Linkify></div>
                            <div className="col-12 text-right date-right pe-0" style={{ position: 'relative', top: '10px', fontSize: '13px' }}>
                                <span>{handleCheckDate(message.DateTime)}</span>
                                <br />
                                {
                                    (message.SeenStatus === "Unseen") ?
                                        <BsCheck2All id={message._id} className='ms-1' /> :
                                        (message.SeenStatus !== "Unseen")
                                        &&
                                        <BsCheck2All id={message._id} className='text-primary ms-1' />
                                }
                            </div>
                        </div>
                        <div className="chat-img">
                            <img src={currentUser && currentUser.ProfileIcon ? currentUser.ProfileIcon : "/dist/img/user.png"}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/dist/img/user.png";
                                }} />
                        </div>
                    </>
                }

            </div>
        </Fragment >
    )
}

export default ChatRight
