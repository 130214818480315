import React, { Fragment, useState } from "react";

const FileInput = ({ label, sup, name, formik, noFormik, accept, className, setPicturePreview, fileInputKey, onChangeInput }) => {

    const handleChange = (e) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            setPicturePreview(reader.result);
        };
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    return (
        <Fragment>
            <div className="col-12">
                {
                    label !== '' &&
                    <h6>{label}<sup className='text-danger'>{sup}</sup></h6>
                }
                <input
                    key={fileInputKey}
                    accept={accept}
                    type="file"
                    className={`w-100 ${className}`}
                    id={name}
                    name={name}
                    onChange={(e) => { onChangeInput(e); handleChange(e); }}
                    onBlur={!noFormik ? formik.handleBlur : null}
                />
            </div>
        </Fragment>
    );
};
export default FileInput