import React, { Fragment } from 'react'
import { BsCheck2All } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const ChatLeftFile = ({ message }) => {
    async function toDataURL(url) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }
    async function downloadFile(message) {
        var el = document.createElement("a");
        el.setAttribute("href", await toDataURL(process.env.REACT_APP_API_URL + message.Message.slice(3)));
        el.setAttribute("download", message.FileName);
        el.target = '_blank'
        document.body.appendChild(el);
        el.click();
        el.remove();
    }
    const handleCheckDate = (dateTime) => {
        var d = new Date();
        var today = ("0" + d.getDate()).slice(-2) +
            "/" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "/" +
            d.getFullYear()

        var getDate = dateTime.slice(0, 10);
        if (today === getDate) {
            return dateTime.slice(11);
        } else {
            if (d.getFullYear().toString() === dateTime.slice(6, 10)) {
                return dateTime.slice(0, 5) + dateTime.slice(10);
            } else {
                return dateTime;
            }
        }
    }
    function handleFileImage(imgName) {
        if (imgName.includes('docx') || imgName.includes('doc')) {
            return '/dist/img/svg/DOC-file-format.svg'
        }
        else if (imgName.includes('xlsx') || imgName.includes('xls')) {
            return '/dist/img/svg/XLSX-file-format.svg'
        }
        else if (imgName.includes('pdf')) {
            return '/dist/img/svg/PDF-file-format.svg'
        }
        else if (imgName.includes('txt')) {
            return '/dist/img/svg/TXT-file-format.svg'
        }
        else if (imgName.includes('zip')) {
            return '/dist/img/svg/7ZIP-file-format.svg'
        }
        else if (imgName.includes('rar')) {
            return '/dist/img/svg/RAR-file-format.svg'
        }
        else if (imgName.includes('html')) {
            return '/dist/img/svg/HTML-file-format.svg'
        }
        else if (imgName.includes('gif')) {
            return '/dist/img/svg/GIF-file-format.svg'
        }
        else {
            return '/dist/img/svg/file-file-format.svg'
        }
    }
    return (
        <Fragment>
            <div className="left-chat">
                {
                    message.ReceiverStatus === 'Active'
                    &&
                    <>
                        <div className="chat-img">
                            <img src={message.SenderInfo && message.SenderInfo.length > 0 ? message.SenderInfo[0].ProfileIcon ? message.SenderInfo[0].ProfileIcon : "/dist/img/user.png" : "/dist/img/user.png"} onError={i => i.target.style.display = 'none'} />
                        </div>
                        <div className="left-msg">
                            <div className="col-12 text-left ps-0" style={{ height: '125px' }}>
                                <span className='opacity-7' onClick={() => {
                                    downloadFile(message);
                                    toast.warning('Downloading,Please wait...');

                                }}>

                                    {
                                        message.FileName.includes('.pdf') ?
                                            <Document
                                                error={
                                                    <img onClick={() => {
                                                        downloadFile(message);
                                                        toast.warning('Downloading,Please wait...');
                                                    }}
                                                        title='Click to download'
                                                        src={handleFileImage(message.FileName)}
                                                        alt="left-img"
                                                        className='opacity-7'
                                                        style={{
                                                            width: '125px', height: '125px', borderRadius: '20px 20px 0px 20px', padding: '10px', background: 'gainsboro',
                                                            border: '2px solid var(--whatsapp)'
                                                        }} />}
                                                loading={
                                                    <img onClick={() => {
                                                        downloadFile(message);
                                                        toast.warning('Downloading,Please wait...');

                                                    }}
                                                        title='Click to download'
                                                        src={handleFileImage(message.FileName)}
                                                        className='opacity-7'
                                                        alt="left-img" style={{
                                                            width: '125px', height: '125px', position: 'relative',
                                                            left: '0px', borderRadius: '20px 20px 20px 0px', padding: '10px', background: 'gainsboro',
                                                            border: '2px solid var(--indigo)'
                                                        }} />}
                                                onPassword={
                                                    <img onClick={() => {
                                                        downloadFile(message);
                                                        toast.warning('Downloading,Please wait...');

                                                    }}
                                                        title='Click to download'
                                                        src={handleFileImage(message.FileName)}
                                                        className='opacity-7'
                                                        alt="left-img" style={{
                                                            width: '125px', height: '125px', position: 'relative',
                                                            left: '0px', borderRadius: '20px 20px 20px 0px', padding: '10px', background: 'gainsboro',
                                                            border: '2px solid var(--indigo)'
                                                        }} />}
                                                file={process.env.REACT_APP_API_URL + message.Message.slice(3)}
                                                className={'left-canv'}>
                                                <Page height={125} pageNumber={1} />
                                            </Document>
                                            :
                                            <img onClick={() => {
                                                downloadFile(message);
                                                toast.warning('Downloading,Please wait...');

                                            }}
                                                title='Click to download'
                                                src={handleFileImage(message.FileName)}
                                                className='opacity-7'
                                                alt="left-img" style={{
                                                    width: '125px', height: '125px', position: 'relative',
                                                    left: '0px', borderRadius: '20px 20px 20px 0px', padding: '10px', background: 'gainsboro',
                                                    border: '2px solid var(--indigo)'
                                                }} />
                                    }
                                </span>
                            </div>
                            <div className="left-chat-pointer"></div>
                            <div className="chat shadow">
                                {
                                    message.FileName
                                }
                            </div>
                            <div className="col-12 p-0 text-left" style={{ position: 'relative', fontSize: '13px' }}>
                                <span>{handleCheckDate(message.DateTime)}</span>
                            </div>
                        </div>
                    </>
                }

            </div>
        </Fragment>
    )
}

export default ChatLeftFile