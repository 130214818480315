import React, { Fragment, useEffect, useState } from 'react';
import ChatAdmin from './chat-admin';
import ChatLeft from './chat-left';
import ChatLeftFile from './chat-left-file';
import ChatLeftImage from './chat-left-img';
import ChatRight from './chat-right';
import ChatRightFile from './chat-right-file';
import ChatRightImage from './chat-right-img';

const ChatMiddle = ({ message, socket, scrollChatMiddle, currentUser }) => {

    useEffect(() => {
        scrollChatMiddle();
    }, [message]);
    return (
        <Fragment >
            {message &&
                (<Fragment>
                    {
                        message.map((messages, index) => ( 
                            messages.SenderID === currentUser._id ?
                                <Fragment >
                                   
                                    {

                                        messages.Type === 'text' &&
                                        <ChatRight message={messages} key={index} IsWhatsapp={true} currentUser={currentUser} />
                                    }
                                    {
                                        messages.Type === 'image' &&
                                        <ChatRightImage message={messages} key={index} IsWhatsapp={true} currentUser={currentUser} />
                                    }
                                    {
                                        messages.Type === 'file' &&
                                        <ChatRightFile message={messages} key={index} IsWhatsapp={true} currentUser={currentUser} />
                                    }
                                    {
                                        messages.Type === 'Empty'
                                        &&
                                        <ChatAdmin message={{ Message: 'No Chat Found', DateTime: '' }} key={index} />
                                    }
                                </Fragment>
                                :
                                <Fragment >
                                    {

                                        messages.Type === 'text' &&
                                        <ChatLeft message={messages} key={index} />
                                    }
                                    {
                                        messages.Type === 'image' &&
                                        <ChatLeftImage message={messages} key={index} />
                                    }
                                    {
                                        messages.Type === 'file' &&
                                        <ChatLeftFile message={messages} key={index} />
                                    }
                                    {
                                        messages.Type === 'Empty'
                                        &&
                                        <ChatAdmin message={{ Message: 'No Chat Found', DateTime: '' }} key={index} />
                                    }
                                </Fragment>

                        ))
                    }
                </Fragment>
                )
            }

            {/* <ChatRight message={'Hello'} />
                <ChatLeft message={'How are you?'} />
                <ChatRight message={'I\'m find. you?'} />
                <ChatLeft message={'good.'} />
                <ChatRight message={'I need to check that chat app is working find or not. can you help me?'} />
                <ChatLeft message={'Yeah. sure! How can i help you?'} />
                <ChatRight message={'You don\'t need to do anything. Just chat with me.'} />
                <ChatLeft message={'Okay.'} />
                <ChatLeft message={'I think this will be enough for testing. Thank you'} />
                <ChatRight message={'You\'re most welcome'} />
                <ChatLeft message={'Okay bye.'} />
                <ChatRight message={'Bye.'} /> */}
        </Fragment>
    );
};

export default ChatMiddle;
