import { Fragment, Suspense, useEffect, useState } from "react";
import { selectCurrentUser } from "./redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import AppRoutes from "./app-routes";
import { useBeforeunload } from "react-beforeunload";
import CheckPasswordModal from "./components/features/check-password-modal";
import { setCurrentUser } from "./redux/user/user.action";
import { useLocation, useNavigate } from "react-router-dom";

const App = ({ currentUser, setCurrentUser }) => {
  const [windowHeight, setWindowHeight] = useState([window.innerHeight]);
  const [modalShow, setModalShow] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    window.addEventListener("resize", changeWindowWidth);
  }, []);
  const changeWindowWidth = () => {
    setWindowHeight([window.innerHeight]);
  };

  // new Date().getMinutes + 30
  // useBeforeunload((event) => {
  //   alert(value)
  //   if (value !== '') {
  //     event.preventDefault();
  //   }
  // });
  useEffect(() => {
    var time = localStorage.getItem("time");
    var localDt = new Date(time).getTime();
    var currentDt = Date.now();
    // if (currentUser === null) {
    //   navigate("/");
    //   return;
    // }
    if (time !== null) {
      if (localDt < currentDt) {
        setCurrentUser(null);
        navigate("/");
      } else {
        setModalShow(true);
      }
    } else {
      setModalShow(false);
    }

    return () => {};
  }, []);
  // window.onbeforeunload = (event) => {
  //   const e = event || window.event;
  //   // Cancel the event
  //   e.preventDefault();
  //   if (e) {
  //     localStorage.setItem('time', addMinutes(1));
  //     e.returnValue = 'Are you sure you want to close?'; // Legacy method for cross browser support
  //   } else {
  //     localStorage.setItem('check', 'yes');

  //   }
  //   return 'Are you sure you want to close?'; // Legacy method for cross browser support
  // };

  return (
    <Fragment>
      <AppRoutes height={windowHeight} />
      {currentUser && (
        <CheckPasswordModal
          modalShow={modalShow}
          currentUser={currentUser}
          setModalShow={setModalShow}
        />
      )}
    </Fragment>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
