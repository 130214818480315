import React, { Fragment } from 'react'

const TextField = ({ label, sup, type, name, divClass, className, value, readonly, placeholder, ...props }) => {

    return (
        <Fragment>
            <div className={`col-12 ${divClass}`}>
                <div className="text-form-group">
                    <input
                        type={type}
                        className={`input ${className}`}
                        autoComplete={`${type === 'password' ? 'new-password' : 'off'}`}
                        id={name}
                        readOnly={readonly}
                        required
                        value={value ? value : ''}
                        {...props} />
                    <label htmlFor={name}>{label}</label>
                </div>
            </div>
        </Fragment>
    )
}

export default TextField
