import React, { Fragment } from 'react'

const PreLoader = () => {
    return (
        <Fragment>
            <div className="row mt-0">
                <div className="col-12 loader h-100vh">
                    <div className="spin"></div>
                    {/* <img src="/dist/img/location.png" alt="logo" /> */}
                </div>
            </div>
        </Fragment>
    )
}

export default PreLoader
