import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import agent from '../../api/agent';
import { setCurrentUser } from '../../redux/user/user.action';
import { connect } from 'react-redux';

const UserVerifier = ({ height, setCurrentUser }) => {
    let naviagte = useNavigate();
    let { id } = useParams();
    const { data, refetch } = useQuery(["Get-info", id], () => agent.User.loadOwnInformation({ UserId: id }),
        {
            refetchOnWindowFocus: false,//turned off on window forcus refetch option
            enabled: false, // turned off by default, manual refetch is needed 
            onSuccess: (d) => {
                setCurrentUser(d[0]);
                naviagte('/chat')
            }
        }
    );
    useEffect(() => {
        setCurrentUser(null)
    }, [])
    useEffect(() => {
        refetch();
    }, [id])
    return (
        <Fragment>
            <div className="login-content" style={{ height: height - 5 + 'px' }}>
                <div className='float-form text-white'>
                    <img src="/dist/img/spinner.gif" alt="pre-loader" />
                </div>
            </div>
        </Fragment>
    )
}
const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
})
export default connect(null, mapDispatchToProps)(UserVerifier)