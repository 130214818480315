import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'

const ChatTop = ({ backList, userDetails, handleEndConversation, userModalShow, setUserModalShow }) => {


    return (
        <Fragment>
            {
                userDetails?.UserInfo[0]?.UserType === 'user'
                &&
                <Fragment>
                    <div className="chat-top">
                        <div className="img-div d-flex text-left justify-content-center">
                            <span onClick={backList} className='d-flex d-md-none ms-3 mt-3'> <i className="fas fa-chevron-left fa-lg" ></i></span>
                            <img src={userDetails && userDetails?.UserInfo[0]?.ProfileIcon ? userDetails.UserInfo[0].ProfileIcon : "/dist/img/user.png"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/dist/img/user.png";
                            }} />
                        </div>
                        <div className="name-div text-left">
                            <h5 className='mt-3' onClick={() => setUserModalShow(!userModalShow)}>{userDetails?.UserInfo[0].UserName}</h5>
                            <h6 id='userId' className='d-none'>{userDetails?.UserInfo[0]._id}</h6>
                            <h6 id='userType' className='d-none'>{userDetails?.UserInfo[0].UserType}</h6>
                        </div>
                    </div>
                </Fragment>
            }

        </Fragment>
    )
}
export default ChatTop
