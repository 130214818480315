import { useFormik } from 'formik';
import React from 'react'
import { useEffect } from 'react';
import { Fragment } from 'react'
import { Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import agent from '../../api/agent';
import * as Yup from 'yup'
import TextField from '../core/text-field';

const CheckPasswordModal = ({
    currentUser,
    modalShow,
    setModalShow
}) => {
    const modalClick = (e) => {
        if (e.target.className === "fade modal show") {
            e.preventDefault();
        }
    };
    const { isLoading, mutate } = useMutation((values) =>
        agent.Auth.login(values), { retry: 2 }
    );
    useEffect(() => {
        formik.setFieldValue('Email', currentUser && currentUser.UserName)

        return () => {

        }
    }, [modalShow])

    const formik = useFormik({
        initialValues: {
            Email: '',
            Password: "",
        },
        validationSchema: Yup.object({
            Email: Yup.mixed().required(),
            Password: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
        }
    })
    function handleConfirm() {
        mutate(formik.values, {
            onSuccess: (data) => {
                if (data === 'Password Incorrect') {
                    toast.error('Wrong password');
                }
                else if (data === 'not exist') {
                    toast.error('Wrong password');
                }
                else if (data === 'No user found') {
                    toast.error('Wrong password');
                }
                else {
                    setModalShow(!modalShow)
                }
            },
            onError: (error) => {
                toast.warning(error);
            }
        })
    }
    return (
        <Fragment>
            <div onClick={(e) => modalClick(e)}>
                <Modal show={modalShow} size="sm" style={{ background: ' rgba(0, 0, 0, 0.7)' }} centered>
                    <Modal.Header className="p-0 border-0">
                        <div className="row w-100">
                            <div className="col-12 pt-2 ps-4">
                                <h6></h6>
                            </div>
                            {/* <div className="col-6 p-2 pe-3 text-right">
                                <span className="cursor-pointer" onClick={(e) => { setModalShow(!modalShow); }}><i className="fas fa-times"></i></span>
                            </div> */}
                        </div>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-12 root-div mb-4">

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <h6>Password</h6>
                                            <TextField
                                                divClass={'p-0'}
                                                type={'password'} placeholder={'Password'}
                                                {...formik.getFieldProps("Password")} />
                                        </div>

                                        <div className="col-12 p-0">
                                            <button
                                                disabled={!(formik.isValid && formik.dirty)}
                                                type="submit"
                                                className='btn btn-primary mt-2 w-100'
                                                onClick={() => handleConfirm()}
                                            >
                                                {isLoading && (
                                                    <span
                                                        className="spinner-border spinner-border-sm me-3"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                )}
                                                Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>

        </Fragment >
    )
}

export default CheckPasswordModal