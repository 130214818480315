import React, { Fragment } from 'react'
import { getLinkPreview } from "link-preview-js";
import { useEffect } from 'react';
import { useState } from 'react';

const svgImg = () => {

    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet" >

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M3685 5114 c-210 -31 -391 -93 -545 -186 -103 -63 -229 -180 -676
-630 -556 -559 -593 -604 -684 -831 l-36 -90 -89 -36 c-99 -40 -176 -82 -275
-150 -82 -56 -1030 -997 -1109 -1101 -327 -427 -358 -1009 -79 -1470 94 -153
275 -335 428 -428 210 -126 427 -186 680 -187 301 0 551 84 790 266 101 77
1044 1026 1102 1109 65 93 107 171 148 273 l36 90 89 36 c230 93 275 130 833
685 562 559 615 621 704 821 84 189 113 326 113 535 -1 303 -84 550 -266 790
-189 249 -460 417 -784 485 -77 16 -318 28 -380 19z m376 -223 c410 -91 736
-419 831 -835 16 -70 21 -125 21 -236 0 -172 -20 -277 -82 -426 -78 -186 -111
-226 -620 -737 -256 -258 -500 -497 -543 -531 -42 -33 -112 -80 -156 -104
l-79 -42 -1 135 c0 74 -5 172 -12 217 l-12 83 479 480 c287 287 494 503 516
537 137 211 153 479 41 701 -41 82 -56 103 -131 179 -174 178 -432 248 -678
183 -73 -20 -170 -66 -218 -106 -61 -50 -59 -126 4 -163 39 -23 81 -17 145 22
81 49 148 67 254 67 194 0 355 -97 439 -265 74 -148 77 -294 9 -445 -30 -66
-55 -93 -477 -517 -245 -247 -448 -448 -451 -448 -3 0 -21 30 -39 68 -118 240
-323 450 -566 578 -50 26 -92 49 -94 51 -3 2 74 83 171 181 110 110 179 187
183 205 19 75 -47 141 -122 122 -29 -7 -279 -247 -398 -382 -129 -147 -188
-296 -188 -478 0 -175 58 -327 177 -467 125 -146 166 -239 166 -374 0 -79 -16
-170 -31 -180 -13 -8 -149 57 -223 108 -220 149 -384 377 -454 633 -24 92 -27
115 -27 280 0 166 2 188 28 279 32 116 87 238 153 338 35 54 182 207 548 574
409 409 517 511 586 557 139 91 306 155 465 176 97 14 283 5 386 -18z m-1417
-1784 c289 -149 486 -408 568 -747 8 -35 13 -118 13 -230 0 -160 -3 -184 -28
-275 -31 -115 -86 -235 -154 -338 -34 -52 -183 -209 -547 -573 -408 -408 -517
-511 -586 -557 -443 -291 -1013 -234 -1385 138 -372 372 -429 943 -138 1385
45 68 150 179 522 553 256 258 500 497 543 531 42 33 113 81 157 104 l81 43 0
-143 c0 -79 5 -177 11 -218 l12 -75 -423 -420 c-511 -508 -559 -560 -616 -680
-99 -206 -98 -408 3 -615 38 -79 60 -109 132 -181 72 -72 102 -94 181 -132
271 -132 558 -90 790 115 118 105 858 851 909 917 96 123 144 265 144 426 0
181 -58 329 -185 476 -81 93 -131 182 -148 260 -14 64 -9 219 9 262 13 34 21
33 135 -26z m-825 -694 c118 -241 323 -451 566 -579 50 -26 92 -49 95 -51 2
-2 -194 -202 -435 -444 -412 -412 -445 -442 -517 -478 -148 -75 -297 -77 -446
-6 -99 47 -172 118 -221 216 -74 146 -77 293 -9 444 30 66 55 93 477 518 245
246 448 447 451 447 3 0 21 -30 39 -67z"/>
                <path d="M3135 4118 c-69 -39 -63 -143 10 -174 70 -29 135 15 135 91 0 71 -83
118 -145 83z"/>
                <path d="M1745 4861 c-11 -5 -29 -19 -40 -31 -19 -21 -20 -36 -20 -362 l0
-340 33 -29 c45 -40 84 -39 128 5 l35 35 -3 335 -3 336 -25 25 c-28 27 -74 39
-105 26z"/>
                <path d="M822 4345 c-39 -17 -63 -65 -55 -110 4 -22 63 -87 226 -252 121 -122
236 -230 255 -239 48 -22 100 -3 124 46 31 65 22 78 -206 308 -116 118 -222
222 -236 231 -37 27 -71 31 -108 16z"/>
                <path d="M345 3431 c-64 -27 -81 -110 -32 -158 l27 -28 336 -3 335 -3 36 36
c31 31 35 40 30 72 -4 20 -18 48 -32 62 l-25 26 -328 2 c-180 1 -336 -2 -347
-6z"/>
                <path d="M4104 1856 c-24 -24 -34 -43 -34 -66 0 -23 10 -42 34 -66 l35 -35
335 3 336 3 27 28 c38 38 38 96 0 134 l-27 28 -336 3 -335 3 -35 -35z"/>
                <path d="M3790 1372 c-49 -24 -68 -77 -46 -124 9 -19 117 -134 239 -255 237
-235 250 -244 315 -217 39 16 66 70 56 112 -7 26 -395 430 -454 471 -40 28
-72 32 -110 13z"/>
                <path d="M3264 1016 l-35 -35 3 -335 3 -336 28 -27 c38 -38 96 -38 134 0 l28
27 3 336 3 335 -35 35 c-24 24 -43 34 -66 34 -23 0 -42 -10 -66 -34z"/>
            </g>
        </svg>
    )
}
const LinkPreview = ({ url, className }) => {

    const [urlData, setUrlData] = useState();

    useEffect(() => {
        getLinkPreview(url)
            .then((data) => {
                setUrlData(data);
            })
            .catch((err) => {
                setUrlData(null);
            });
    }, [url]);
    const getDomain = () => {
        let domain = new URL(url);
        return domain.hostname;
    }

    const css = `
    .dflex{
        display:flex!important;
        padding:10px 10px;
        background:rgba(0, 0, 0,0.3);
        border-radius:5px;
        min-width:auto; 
    }
    .link-preview a{
        text-decoration:none;
        color:white
    }
     .left-side{ 
        background:rgba(0, 0, 0,0.7)!important;

     }
    a:hover{
        text-decoration:none;
        color:white;
    }
    .icon-div{
        width:50px;
        text-align:center;
    }
    .icon-div img{
        max-height:50px;
        max-width:50px;
        border-radius:5px; 
    }
    .icon-div svg{
        height:40px;
        width:40px; 
        filter: invert(100%) sepia(0) saturate(132%) hue-rotate(0) brightness(87%) contrast(100%);
    }
    .content-div{
        width:calc(100% - 50px)
    }
    .wrap-text{
        text-overflow:ellipsis;
        white-space:nowrap;
        margin-bottom:0
    }
    `
    return (
        <Fragment>
            <div className={`link-preview`}>
                <style>{css}</style>
                <a href={url} target='_blank'>
                    <div className={`${className} dflex`}>

                        <div className="icon-div">
                            {
                                urlData ?
                                    <img src={urlData && urlData.favicons && urlData.favicons.length > 0 ? urlData.favicons[0] : svgImg()} alt="" />
                                    :
                                    svgImg()
                            }
                        </div>
                        <div className="content-div">
                            <h6 className='wrap-text'>{urlData && urlData.title ? urlData.title : getDomain()}</h6>
                            {
                                urlData && urlData.description ?
                                    <p className='wrap-text'>{urlData.description}</p>
                                    :
                                    <p className="wrap-text">{url}</p>
                            }
                        </div>

                    </div>
                </a>
            </div>
        </Fragment >
    )
}

export default LinkPreview
