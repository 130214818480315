import React, { Fragment } from 'react'
import { BsCheck2All } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const ChatRightFile = ({ message, IsWhatsapp, currentUser }) => {
    async function toDataURL(url) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }
    async function downloadFile(message) {
        var el = document.createElement("a");
        el.setAttribute("href", await toDataURL(process.env.REACT_APP_API_URL + message.Message.slice(3)));
        el.setAttribute("download", message.FileName);
        el.target = '_blank'
        document.body.appendChild(el);
        el.click();
        el.remove();
    }
    const handleCheckDate = (dateTime) => {
        var d = new Date();
        var today = ("0" + d.getDate()).slice(-2) +
            "/" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "/" +
            d.getFullYear()

        var getDate = dateTime.slice(0, 10);
        if (today === getDate) {
            return dateTime.slice(11);
        } else {
            if (d.getFullYear().toString() === dateTime.slice(6, 10)) {
                return dateTime.slice(0, 5) + dateTime.slice(10);
            } else {
                return dateTime;
            }
        }
    }
    function handleFileImage(imgName) {
        if (imgName.includes('docx') || imgName.includes('doc')) {
            return '/dist/img/svg/DOC-file-format.svg'
        }
        else if (imgName.includes('xlsx') || imgName.includes('xls')) {
            return '/dist/img/svg/XLSX-file-format.svg'
        }
        else if (imgName.includes('pdf')) {
            return '/dist/img/svg/PDF-file-format.svg'
        }
        else if (imgName.includes('txt')) {
            return '/dist/img/svg/TXT-file-format.svg'
        }
        else if (imgName.includes('zip')) {
            return '/dist/img/svg/7ZIP-file-format.svg'
        }
        else if (imgName.includes('rar')) {
            return '/dist/img/svg/RAR-file-format.svg'
        }
        else if (imgName.includes('html')) {
            return '/dist/img/svg/HTML-file-format.svg'
        }
        else if (imgName.includes('gif')) {
            return '/dist/img/svg/GIF-file-format.svg'
        }
        else {
            return '/dist/img/svg/file-file-format.svg'
        }
    }
    return (
        <Fragment>
            <div className="right-chat">
                {
                    message.SenderStatus === 'Active'
                    &&
                    <>
                        <div className="right-msg">
                            <div className="col-12 text-right mt-1 pe-0" style={{ height: '125px' }}>
                                <span onClick={() => {
                                    downloadFile(message);
                                    toast.warning('Downloading,Please wait...');

                                }}>
                                    {
                                        message.FileName.includes('.pdf') ?
                                            <Document
                                                error={<img onClick={() => {
                                                    downloadFile(message);
                                                    toast.warning('Downloading,Please wait...');
                                                }}
                                                    title='Click to download'
                                                    src={handleFileImage(message.FileName)}
                                                    alt="left-img"
                                                    className='opacity-7'
                                                    style={{
                                                        width: '125px', height: '125px', borderRadius: '20px 20px 0px 20px', padding: '10px', background: 'gainsboro',
                                                        border: '2px solid var(--whatsapp)'
                                                    }} />}
                                                loading={<img onClick={() => {
                                                    downloadFile(message);
                                                    toast.warning('Downloading,Please wait...');
                                                }}
                                                    title='Click to download'
                                                    src={handleFileImage(message.FileName)}
                                                    alt="left-img"
                                                    className='opacity-7'
                                                    style={{
                                                        width: '125px', height: '125px', borderRadius: '20px 20px 0px 20px', padding: '10px', background: 'gainsboro',
                                                        border: '2px solid var(--whatsapp)'
                                                    }} />}
                                                onPassword={
                                                    <img onClick={() => {
                                                        downloadFile(message);
                                                        toast.warning('Downloading,Please wait...');
                                                    }}
                                                        title='Click to download'
                                                        src={handleFileImage(message.FileName)}
                                                        alt="left-img"
                                                        className='opacity-7'
                                                        style={{
                                                            width: '125px', height: '125px', borderRadius: '20px 20px 0px 20px', padding: '10px', background: 'gainsboro',
                                                            border: '2px solid var(--whatsapp)'
                                                        }} />}
                                                file={process.env.REACT_APP_API_URL + message.Message.slice(3)}
                                                className={'float-right right-canv'}>
                                                <Page height={125} pageNumber={1} />
                                            </Document>
                                            :
                                            <img onClick={() => {
                                                downloadFile(message);
                                                toast.warning('Downloading,Please wait...');
                                            }}
                                                title='Click to download'
                                                src={handleFileImage(message.FileName)}
                                                alt="left-img"
                                                className='opacity-7'
                                                style={{
                                                    width: '125px', height: '125px', borderRadius: '20px 20px 0px 20px', padding: '10px', background: 'gainsboro',
                                                    border: '2px solid var(--whatsapp)'
                                                }} />
                                    }

                                </span>
                            </div>
                            <div className={`chat shadow ${IsWhatsapp ? 'bg-whatsapp' : 'bg-messeger'}`}>
                                {
                                    message.FileName
                                }
                            </div>
                            <div className="col-12 text-right date-right pe-0" style={{ position: 'relative', top: '10px', fontSize: '13px' }}>
                                <span>{handleCheckDate(message.DateTime)}</span>
                                {
                                    (message.SeenStatus === "Unseen") ?
                                        <BsCheck2All className='ms-1' /> :
                                        (message.SeenStatus !== "Unseen")
                                        &&
                                        <BsCheck2All className='text-primary ms-1' />
                                }
                            </div>
                        </div>
                        <div className="chat-img">
                            <img src={currentUser && currentUser.ProfileIcon ? currentUser.ProfileIcon : "/dist/img/user.png"}
                                onError={i => i.target.style.display = 'none'} />
                        </div>
                    </>
                }

            </div>
        </Fragment>
    )
}

export default ChatRightFile
