import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import useWindowDimensions from '../../core/windowDimension';

const ImgPrevModal = ({
    modalShow,
    setModalShow,
    message
}) => {
    const { height } = useWindowDimensions()
    async function toDataURL(url) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }
    async function downloadImg(message) {
        var el = document.createElement("a");
        el.setAttribute("href", await toDataURL(process.env.REACT_APP_API_URL + message.Message.slice(3)));
        el.setAttribute("download", message.FileName);

        document.body.appendChild(el);
        el.click();
        el.remove();
    }
    const modalClick = (e) => {
        if (e.target.className === "fade modal show") {
            e.preventDefault();
        }
    };
    const css = `
    .img-modal .modal-content{
        background:transparent!important;
    }
    .close-btn{
        position:fixed;
        top:20px;
        right:20px;
    }
    .download-btn{
        position:fixed;
        top:20px;
        right:50px; 
    }
    `
    return (
        <Fragment>
            <style>{css}</style>
            <div onClick={(e) => modalClick(e)}>
                <Modal show={modalShow} size="xl" className={'img-modal'} style={{ background: ' rgba(0, 0, 0, 0.7)' }} centered>

                    <span className="cursor-pointer text-white download-btn" onClick={() => downloadImg(message)}>
                        <BsDownload size={24} />
                    </span>
                    <span className="cursor-pointer text-white close-btn" onClick={(e) => { setModalShow(!modalShow); }}>
                        <IoMdClose size={24} />
                    </span>
                    <Modal.Body className="p-0">
                        <div className="" onClick={() => downloadImg(message)}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <img src={process.env.REACT_APP_API_URL + message.Message.slice(3)}
                                        style={{ maxHeight: height - 100 + 'px' }}
                                        alt="preview-image" className='prev-img' />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </Fragment>
    )
}

export default ImgPrevModal