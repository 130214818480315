import axios from "axios";

const envUrl = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = `${envUrl}`;

const responseBody = (response) => response?.data;
const requests = {
  get: (url) => axios.get(url).then(responseBody),
  get: (url, request) => axios.get(url, request).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  del: (url) => axios.delete(url).then(responseBody),
};
const Auth = {
  login: (info) => requests.post(`/login`, info),
  adminlogin: (info) => requests.post(`/adminlogin`, info),
};
const User = {
  loadOwnInformation: (info) => requests.post(`/loadOwnInformation`, info),
  loadAllUser: (info) => requests.post(`/loadAllUser`, info),
  loadAllGroup: (info) => requests.post(`/loadAllGroup`, info),
  loadAllRecentChat: (info) => requests.post(`/loadAllRecentChat`, info),
  createGroup: (info) => requests.post(`/createGroup`, info),
  AttachmentSend: (info) => requests.post(`/AttachmentSend`, info),
  loadAllGroupMembers: (info) => requests.post(`/loadAllGroupMembers`, info),
  removeGroupMember: (info) => requests.post(`/removeGroupMember`, info),
  editGroupName: (info) => requests.post(`/editGroupName`, info),
  editGroupImage: (info) => requests.post(`/editGroupImage`, info),
  addNewUserIntoSystem: (info) => requests.post(`/addNewUserIntoSystem`, info),
  searchNewFriend: (info) => requests.post(`/searchNewFriend`, info),
  addNewFriend: (info) => requests.post(`/addNewFriend`, info),
  acceptFriendRequest: (info) => requests.post(`/acceptFriendRequest`, info),
  rejectFriendRequest: (info) => requests.post(`/rejectFriendRequest`, info),
  deleteContactRow: (info) => requests.post(`/deleteContactRow`, info),
  blockAnyUser: (info) => requests.post(`/blockAnyUser`, info),
  loadAllBlockList: (info) => requests.post(`/loadAllBlockList`, info),
  unblockUser: (info) => requests.post(`/unblockUser`, info),
};
export default {
  Auth,
  User,
};
