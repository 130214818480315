import { useFormik } from 'formik'
import React from 'react'
import { Fragment } from 'react'
import { useMutation } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import agent from '../../api/agent'
import { setCurrentUser } from '../../redux/user/user.action'
import TextField from '../core/text-field'

const AddUser = ({ height, setCurrentUser }) => {
    let navigate = useNavigate();
    const { isLoading, mutate } = useMutation((values) =>
        agent.User.addNewUserIntoSystem(values), { retry: 2 }
    );

    const formik = useFormik({
        initialValues: {
            UserName: "",
            Password: "",
            Icon: ''
        },
        validationSchema: Yup.object({
            UserName: Yup.mixed().required(),
            Password: Yup.mixed().required(),
            Icon: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {

        }
    })
    function handleAddUser() {
        mutate(formik.values, {
            onSuccess: (data) => {
                if (data === 'success') {
                    formik.resetForm();
                    toast.success('User added successfully')
                }
                else {
                    toast.error('Failed to add user');
                }
            },
            onError: (error) => {
                toast.warning(error);
            }
        })
    }
    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 root-div">
                    <div className="container">
                        <div className="col-12 mb-5 text-end p-3">
                            <button className="btn btn-danger" onClick={() => {
                                setCurrentUser(null);
                                navigate('/admin-login')
                            }}>Log out</button>
                        </div>
                        <div className="card card-body shadow mt-5 p-md-5" style={{ background: '#fff', color: '#495057' }}>
                            <div className="row m-0">
                                <div className="col-md-12 text-center">
                                    <h3>Add User</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2 col-lg-3"></div>
                                <div className="col-md-8 col-lg-6 mt-3 pt-4">
                                    <div className="row m-0">
                                        <div className="col-12">
                                            <h6>Username</h6>
                                            <TextField
                                                divClass={'p-0'}
                                                placeholder={'e.g. john123'}
                                                {...formik.getFieldProps("UserName")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <h6>Password</h6>
                                            <TextField
                                                divClass={'p-0'}
                                                placeholder={'Password'}
                                                {...formik.getFieldProps("Password")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <h6>Icon</h6>
                                            <TextField
                                                divClass={'p-0'}
                                                placeholder={'Icon'}
                                                {...formik.getFieldProps("Icon")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <button
                                                disabled={!(formik.isValid && formik.dirty)}
                                                type="submit"
                                                className='btn btn-primary mt-2 w-100'
                                                onClick={() => { handleAddUser() }}
                                            >
                                                {isLoading && (
                                                    <span
                                                        className="spinner-border spinner-border-sm me-3"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                )}
                                                Add User</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-lg-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}
const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
})
export default connect(null, mapDispatchToProps)(AddUser)