import { useFormik } from 'formik';
import React, { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import TextField from '../core/text-field';
import agent from '../../api/agent';
import { setCurrentUser } from '../../redux/user/user.action';

const AdminLogin = ({ setCurrentUser }) => {
    let navigate = useNavigate();
    let location = useLocation();

    const { isLoading, mutate } = useMutation((values) =>
        agent.Auth.adminlogin(values), { retry: 2 }
    );

    const formik = useFormik({
        initialValues: {
            Email: "",
            Password: "",
        },
        validationSchema: Yup.object({
            Email: Yup.mixed().required(),
            Password: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            handleAdminLogin()
        }
    })
    function handleAdminLogin() {
        mutate(formik.values, {
            onSuccess: (data) => {
                console.log(data);
                if (data === 'Password Incorrect') {
                    toast.error('Password Mismatch');
                }
                else if (data === 'not exist') {
                    toast.error('User doesn\'t exist');
                }
                else if (data === 'No user found') {
                    toast.error('No user found');
                }
                else {
                    setCurrentUser(data[0]);
                    if (data[0] !== null) {
                        navigate(`/add-user`)
                    }
                }
            },
            onError: (error) => {
                toast.warning(error);
            }
        })
    }
    function addMinutes(minutes) {
        var dt = new Date();
        dt.setMinutes(dt.getMinutes() + minutes)
        return dt;
    }
    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 root-div">

                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-lg-3"></div>
                            <div className="col-md-8 col-lg-6 mt-3 pt-4">
                                <div className="card card-body shadow mt-5 p-md-5" style={{ background: '#fff', color: '#495057' }}>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3>{location.pathname === '/admin-login' ?
                                                'Admin Login'
                                                :
                                                'Login'
                                            }</h3>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-12 col-xl-10 mt-3 p-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6>Email</h6>
                                                    <TextField
                                                        divClass={'p-0'}
                                                        placeholder={'name@example.com'}
                                                        {...formik.getFieldProps("Email")}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <h6>Password</h6>
                                                    <TextField
                                                        divClass={'p-0'}
                                                        type={'password'} placeholder={'Password'}
                                                        {...formik.getFieldProps("Password")} />
                                                </div>
                                                <div className="col-12 p-2">
                                                    {!formik.isValid && (
                                                        <span className="text-danger mt-2">
                                                            Please fill all the fields
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-12">
                                                    <button
                                                        disabled={!(formik.isValid && formik.dirty)}
                                                        type="submit"
                                                        className='btn btn-primary mt-2 w-100'
                                                        onClick={() => {
                                                            handleAdminLogin();
                                                        }}>
                                                        {isLoading && (
                                                            <span
                                                                className="spinner-border spinner-border-sm me-3"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        )}
                                                        Log in</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-2 col-lg-3"></div>
                        </div>

                    </div>
                </div>
            </form>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user))
})
export default connect(null, mapDispatchToProps)(AdminLogin);
