import { Modal } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react'
import TextField from '../../core/text-field';
import FileInput from '../../core/file-input';
import { MdAddPhotoAlternate } from 'react-icons/md'
import Button from '../../core/button';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import agent from '../../../api/agent';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { BsSearch } from 'react-icons/bs';

const CreateGroupModal = ({
    modalShow,
    setModalShow,
    currentUser,
    recentRefetch
}) => {
    const [picturePreview, setPicturePreview] = useState(null);
    const [fileInputKey, setFileInputKey] = useState(0);
    const [file, setFile] = useState(null);
    const [searchText, setSearchText] = useState('')
    const [arrayData, setArrayData] = useState([]);
    const [UserData, setUserData] = useState([])

    const { isLoading, mutate } = useMutation((values) =>
        agent.User.addNewFriend(values), { retry: 2 }
    );

    const { data: uDt, refetch, isLoading: userLoading } = useQuery(["chat-list"], () => agent.User.searchNewFriend({ UserId: currentUser._id, Search: searchText }),
        {
            refetchOnWindowFocus: false,//turned off on window focus refetch option
            enabled: false, // turned off by default, manual refetch is needed
            onSuccess: (d) => {
                setUserData(d)
            }
        }
    );

    const modalClick = (e) => {
        if (e.target.className === "fade modal show") {
            e.preventDefault();
        }
    };

    const onChangeImageInput = (e) => {
        setFile(e.currentTarget.files[0]);
        formik.setFieldValue('file', e.currentTarget.files[0])
    };
    const handleArray = (e, rowData) => {
        if (e.target.checked) {
            setArrayData((arrayData) => [...arrayData, rowData._id]);
        } else {
            setArrayData((oldData) => {
                return oldData.filter(id => id !== rowData._id);
            });
        }
    }
    const handleCheckAll = (e) => {
        const checkedbox = document.getElementsByName('rowCheckBox');
        for (let index = 0; index < checkedbox.length; index++) {
            checkedbox[index].checked = e.target.checked;

        }
        if (e.target.checked) {
            setArrayData([]);
            UserData && UserData.map((i) => (
                setArrayData((arrayData) => [...arrayData, i._id])
            ));
        } else {
            setArrayData([]);
        }
    }
    const handleAddFriend = (e, item) => {
        e.preventDefault();
        let values = {
            UserId: currentUser._id,
            SelectId: item._id
        }
        mutate(values, {
            onSuccess: (data) => {
                if (data === 'success') {
                    setModalShow(!modalShow);
                    recentRefetch();
                    toast.success('Ajouté avec succès');
                } else {
                    toast.warning('Impossible d\'ajouter');
                }
            },
            onError: (error) => {
                toast.warning(error);
            }
        })
    }
    const formik = useFormik({
        initialValues: {
            GroupName: '',
            UserList: [],
            file: ''
        },
        validationSchema: Yup.object({
            GroupName: Yup.mixed().required(),
            UserList: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
        }
    })
    useEffect(() => {
        formik.setFieldValue('UserList', arrayData);
    }, [arrayData])
    useEffect(() => {
        formik.resetForm();
        setUserData([])
        setPicturePreview(null);
        setArrayData([]);
        if (UserData !== null) {
            const checkedbox = document.getElementsByName('rowCheckBox');
            for (let index = 0; index < checkedbox.length; index++) {
                checkedbox[index].checked = false;
            }
        }
    }, [modalShow])
    function handleChooseImage(e) {
        e.preventDefault();
        var input = document.createElement("input");
        input.type = "file";
        input.multiple = false;
        input.accept = '.png,.jpg,.jpeg';
        input.click();
        input.onchange = (e) => {
            var file = e.currentTarget.files[0]
            if (file.type === "" || file.type.includes('application')) {
                toast.error('Only image accepted')
                return false;
            }
            if (file.type !== "" || file.type.includes('png') || file.type.includes('jpg') || file.type.includes('jpeg')) {
                setFile(file);
                setPicturePreview(URL.createObjectURL(e.currentTarget.files[0]))
                formik.setFieldValue('file', file);
            } else {
                toast.error('Invalid Image Format')
                return false;
            }

        }
    }
    const imgCss = {
        width: '125px',
        height: '125px',
        borderRadius: '50%',
        position: 'relative',
        left: '20px'
    }
    return (
        <Fragment>
            <div onClick={(e) => modalClick(e)}>
                <Modal show={modalShow} size="md" style={{ background: ' rgba(0, 0, 0, 0.7)' }} centered>
                    <Modal.Header className="p-0 border-0">
                        <div className="row w-100">
                            <div className="col-6 pt-2 ps-4">
                                <h5>Ajouter un nouvel ami</h5>
                            </div>
                            <div className="col-6 p-2 pe-3 text-right">
                                <span className="cursor-pointer" onClick={(e) => { setModalShow(!modalShow); }}><i className="fas fa-times"></i></span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <form >
                            <div className="row mb-4">

                                <div className="col-12 mt-4 mb-4 d-flex">
                                    <input type="text" className="input" onChange={(e) => {
                                        if (e.target.value === '') {
                                            setSearchText('');
                                        } else {
                                            setSearchText(e.target.value);
                                        }

                                    }} placeholder='Rechercher un utilisateur' />
                                    <a className="btn btn-primary ms-1" onClick={() => { refetch(); }}>
                                        {
                                            userLoading
                                                ?
                                                <span
                                                    className="spinner-border spinner-border-sm "
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                :
                                                <BsSearch />
                                        }
                                    </a>
                                </div>
                                {
                                    userLoading &&
                                    <div className="col-12 text-center">
                                        <span
                                            className="spinner-border spinner-border-sm "
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    </div>
                                }

                                <div className="col-12 user-list-div">

                                    <table className="table table-striped table-borderless">
                                        <tbody>
                                            {
                                                UserData && UserData.length > 0 ? UserData.map((item, key) => (
                                                    <tr key={key}>
                                                        <td style={{ paddingLeft: '10px' }}>
                                                            <div className="d-flex">
                                                                <label style={{
                                                                    width: '100%',
                                                                    margin: '0px'
                                                                }}
                                                                    className={'mt-2'}
                                                                >
                                                                    <img src={item && item.ProfileIcon ? item.ProfileIcon : "/dist/img/user.png"} alt="user-pic" className='list-user-pic' onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src = "/dist/img/user.png";
                                                                    }} /><span className="list-user-name wrap-text">{item.UserName}</span>
                                                                </label>
                                                                <button className="btn btn-success" onClick={(e) => handleAddFriend(e, item)} disabled={isLoading}>Ajouter</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                                    :
                                                    <tr>
                                                        <td>
                                                            <span className="text-warning">Aucun utilisateur trouvé</span>
                                                        </td>
                                                    </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </Fragment >
    )
}

export default CreateGroupModal