import React, { Fragment, Suspense } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import AddUser from './components/pages/add-user';
import AdminLogin from './components/pages/admin-login';
import Chat from './components/pages/chat';
import LogIn from './components/pages/log-in';
import NotFound from './components/pages/not-found';
import PreLoader from './components/pages/pre-loader';
import UserVerifier from './components/pages/user-verifier';
import { selectCurrentUser } from './redux/user/user.selector';

const AppRoutes = ({ currentUser, height }) => {
    const [params, setParams] = useState("d=" + new Date().getFullYear().toString().slice(-1) + new Date().getMonth() + 1)

    return (
        <Fragment>
            <Suspense fallback={<PreLoader />}>
                <Routes>
                    {
                        currentUser === null ?
                            <Fragment>
                                <Route path="/chat"
                                    element={<Navigate to="/not-found" replace />} />
                                <Route exact path='/admin-login' element={<AdminLogin height={height} />}></Route>
                                <Route exact path={`/${params}`} element={<LogIn height={height} />}></Route>
                                <Route path='*' element={<NotFound />} />
                            </Fragment>
                            :
                            currentUser.UserType === 'Admin' ?
                                <>
                                    <Route exact path='/add-user' element={<AddUser height={height} />}></Route>
                                </>
                                :
                                currentUser.UserType === 'user' &&
                                <Fragment>
                                    <Route path='/admin-login'
                                        element={<Navigate to="/chat" replace />} />
                                    <Route path='/add-user'
                                        element={<Navigate to="/chat" replace />} />
                                    <Route path={`/${params}`}
                                        element={<Navigate to="/chat" replace />} />
                                    <Route path=""
                                        element={<Navigate to="/chat" replace />} />
                                    <Route exact path='/chat' element={<Chat />}></Route>
                                    <Route path='*' element={<NotFound />} />
                                </Fragment>

                    }
                </Routes>
            </Suspense>
        </Fragment >
    )
}
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});
export default connect(mapStateToProps, null)(AppRoutes)