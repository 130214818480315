import React, { Fragment } from 'react';

const ChatAdmin = ({ message }) => {
    return (
        <Fragment>
            <div className="right-chat">
                <div className="right-msg-admin">
                    <div className="chat shadow bg-admin">{message.Message}</div>
                    <div className="col-12 text-center mt-1">
                        <span>{message.DateTime}</span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ChatAdmin;
